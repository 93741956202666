<template>
  <div class="base-container">
    <div class="header-mol">
      <div class="info">
        <h3 class="title">
          全部预约
        </h3>
        <p class="summary">
          相关预约已推送给相关分析师，分析师可登录P+APP查看处理，本页面仅做数据展示
        </p>
      </div>
    </div>
    <div class="pt20">
      <el-input
        placeholder="委托用户、电话以及分析师名称"
        v-model="queryData.keyword"
        style="width: 320px;"
        class="f-search-bar"
      >
        <el-button slot="append" @click="search">搜索</el-button>
      </el-input>
    </div>
    <el-table class="body" :data="list">
      <el-table-column label="委托用户">
        <div slot-scope="scope" class="x aic">
          <img :src="scope.row.customerAvatarPath" class="logo" />
          <div style="height:50px" class="y3">
            <p class="fs16">{{ scope.row.customerName }}</p>
            <p class="color9">来自 {{ scope.row.appTypeName }}</p>
          </div>
        </div>
      </el-table-column>
      <el-table-column label="预约内容">
        <template slot-scope="scope">
          <div style="height:50px" class="y3">
            <p>同行：{{ scope.row.appointmentNum }}人</p>
            <p>时间： {{ scope.row.appointmentDate }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="联系人信息">
        <template slot-scope="scope">
          <div style="height:50px" class="y3">
            <p>{{ scope.row.customerPhone }}</p>
            <p>{{ scope.row.customerName }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="楼盘名称">
        <template slot-scope="scope">
          <p>{{ scope.row.estateName }}</p>
        </template>
      </el-table-column>
      <el-table-column label="提交时间" width="200px">
        <template slot-scope="scope">
          <p>{{ scope.row.inDate }}</p>
        </template>
      </el-table-column>
      <el-table-column label="接单经纪人">
        <template slot-scope="scope">
          <div class="x aic">
            <p v-for="(item, index) in scope.row.receiveUserList" :key="index">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link blue pointer">
                  {{ item.customerName }}
                </span>
                <el-dropdown-menu slot="dropdown">
                  <div class="x dropdown">
                    <img :src="item.avatarPath" class="logo" />
                    <div>
                      <p class="fs16">{{ item.customerName }}</p>
                      <p class="color9">{{ item.description }}</p>
                    </div>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
              <span v-if="index < scope.row.receiveUserList.length - 1">
                、
              </span>
            </p>
            <p
              v-if="
                !scope.row.receiveUserList || !scope.row.receiveUserList.length
              "
            >
              --
            </p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageNumChange"
      :current-page.sync="queryData.pageNum"
      :page-sizes="pageSizes"
      :page-size="queryData.pageSize"
      layout="sizes, prev, pager, next,jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { appointmentList } from "@/api/appManage";
export default {
  data() {
    return {
      list: [],
      total: 0,
      status: {
        1: "已发布",
        2: "违规下架",
        3: "草稿"
      },
      pageSizes: [5, 10, 20, 30, 50],
      queryData: {
        pageNum: 1,
        pageSize: 10,
        keyword: ""
      }
    };
  },
  created() {
    this.appointmentList();
  },
  methods: {
    search() {
      this.queryData.pageNum = 1;
      this.appointmentList();
    },
    pageSizeChange(num) {
      this.queryData.pageSize = num;
      this.appointmentList();
    },
    pageNumChange(num) {
      this.queryData.pageNum = num;
      this.appointmentList();
    },
    async appointmentList() {
      //获取列表
      const res = await appointmentList(this.queryData);
      if (res) {
        this.list = res.list;
        this.total = res.total;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.logo {
  height: 50px;
  width: 50px;
  min-width: 50px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 10px;
}
.dropdown {
  padding: 10px;
  max-width: 300px;
}
</style>
